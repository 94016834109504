export default {
  // Buttons
  'button.add': 'Add',
  'button.automatic_redirect': 'Click here if you are not automatically redirected.',
  'button.checkout_next': 'Continue with ordering',
  'button.disclaimer': 'Disclaimer',
  'button.download_tickets': 'Download tickets',
  'button.order_edit': 'Change order',
  'button.order_new': 'New order',
  'button.park_regulations': 'Park Regulations',
  'button.payment_submit': 'Complete',
  'button.privacy_statement': 'Privacy Statement',
  'button.remove': 'Remove',
  'button.to_products': 'To the tickets',
  'button.voucher_redeem': 'Redeem voucher',
  'button.voucher_additional': 'One more code',

  // Errors
  'error.api.timeframe_expired': 'One of the chosen time slots is not available anymore. Select a different time slot',
  'error.api.timeframe_full': 'One of the time slots you have selected is unfortunately full. Please select another time slot',
  'error.api.unexpected': 'An error has occurred. Please try again later or contact us at {phoneNumber}',
  'error.api.validation_failed': 'There is a problem with your order. Please try again later or contact us at {phoneNumber}',
  'error.checkout_invalid': 'Your order is not yet complete. Change your order and place it again.',
  'error.max_tickets_reached': 'You have reached the maximum number of tickets',
  'error.max_timeframe_reached': 'You have reached the maximum number of tickets for this timeslot',
  'error.max_tickets_exceeded': 'You exceeded the maximum number of tickets.',
  'error.max_tickets_product_quantity': 'You have reached the maximum number of tickets for {title}',
  'error.order_confirmation_failed': 'An error occurred during order confirmation.',
  'error.paymentmethods_fetch_failed': 'An error occurred while retrieving the payment methods.',
  'error.products_fetch_failed': 'An error occurred while retrieving the products. Try again or contact us on {phoneNumber}',
  'error.voucher_already_exists': 'You have already entered this voucher',
  'error.voucher_redeem_failed': 'Unfortunately, this voucher is invalid',
  'error.voucher_validation_failed': 'An error occurred while validating your voucher',
  'error.voucher_maximum_vouchers_reached': 'You have entered the maximum number of voucher codes.',

  // Forms
  'form.customer.subtitle': 'We need the information below to send your tickets digitally.',
  'form.customer.title': 'Personal information',

  // Form help
  'help.email': 'Your tickets are sent to this email address. Not received, check your junk mail.',

  // Form labels
  'label.agree_with': 'You must agree to the',
  'label.almost_sold_out': 'Almost full',
  'label.and': 'and',
  'label.closed': 'Closed',
  'label.email': 'E-mail address',
  'label.email_verify': 'E-Mail adress (verification)',
  'label.fields_required': '* This field is mandatory',
  'label.first_name': 'First name',
  'label.last_name': 'Last name',
  'label.legend': 'Legend',
  'label.newsletter': '{name} may keep me informed of the latest promotions, adventures, events and personal benefits based on my visit and interests.',
  'label.park_regulations': 'park regulations',
  'label.phone': 'Phone number',
  'label.privacy_policy': 'privacy policy',
  'label.selected_day': 'Selected day',
  'label.sold_out': 'Sold out',
  'label.terms': 'terms and conditions',
  'label.timeframe_from_util': 'Between {from} & {until}',
  'label.today': 'Today',
  'label.voucher_code': 'Voucher Code',

  'placeholder.voucher': 'Your voucher code',

  // Tickets
  'tickets.arrangements.title': 'Available arrangements on',
  'tickets.activities.title': 'Available activities for',
  'tickets.activities.error': 'An error occurred while retrieving the activity ({expositionId}). Try again or contact us on {phoneNumber}',
  'tickets.calendar.info':
    'For groups larger than 20 people it is sometimes possible to book activities on days that are closed or fully booked. For more information, please contact us at <b>088-9000360</b> or email <a>email</a>. State your name, telephone number and when you want to come with any arrival time.',

  // Routes
  'route.checkout': '/en/checkout',
  'route.payment_failed_aquazoo': '/en/page-error',
  'route.payment_failed_aviodrome': '/en/error-message',
  'route.payment_failed_beeksebergen': '/en/page-error',
  'route.payment_failed_dierenrijk': '/en/page-error',
  'route.payment_failed_speelland': '/en/page-error',
  'route.payment_failed_zooparc': '/en/error-message',
  'route.payment_success_aquazoo': '/en/thank-you-for-your-purchase',
  'route.payment_success_aviodrome': '/en/thank-you-for-your-purchase',
  'route.payment_success_beeksebergen': '/en/thank-you-for-your-order',
  'route.payment_success_dierenrijk': '/en-thank-you-for-your-order',
  'route.payment_success_speelland': '/en/thank-you-for-your-order',
  'route.payment_success_zooparc': '/en/thank-you-for-your-purchase',

  // Titles
  'title.404': 'Page not found',
  'title.404_subtitle': 'This page does not exist (anymore), or there is a mistake in the address. Check if the address is spelled correctly',
  'title.additional_products': 'Extra products',
  'title.arrival_date': 'Select your arrival date',
  'title.checkout_redirect': 'Verifying payment',
  'title.checkout_redirect_subtitle': 'We are verifying your payment. You will be redirect shorly',
  'title.free': 'Free',
  'title.libema_footer': 'You will have a great time at {account}, which is part of',
  'title.order_verification_cancelled': 'Oops, this takes too long.',
  'title.order_verification_cancelled_subtitle':
    'Your payment has been received, but your order has not yet been processed. This takes longer than usual. If you do not receive an e-mail within a few minutes, please contact us at +31 (0)88-9000360.',
  'title.payment_failed': 'Oops... Something went wrong!',
  'title.payment_failed_subtitle':
    'Unfortunately, something went wrong. Please try again! Do you need help? Call us at +31 (0)88-9000360 (Mon-Fri until 6:00 pm, Sat-Sun until 5:00 pm).',
  'title.payment_method': 'Choose your payment method',
  'title.payment_method_subtitle': 'To make your booking final, all you have to do is pay. Select one of the payment methods.',
  'title.selected_date': 'Available tickets on',
  'title.shopping_cart': 'Your shopping cart',
  'title.tickets': 'Order your tickets',
  'title.tickets_subtitle_aviodrome': '',
  'title.tickets_subtitle_aquazoo':
    'Are you ready to embark on an expedition? Children under the age of 3 are allowed to explore the park for free. <br />Over the age of 3? Then you can benefit from our online discount.',
  'title.tickets_subtitle_beeksebergen':
    'Are you ready to embark on a safari? Free admission for children under 3 years old. <br />Over the age of 3? Then you can benefit from our online discount.',
  'title.tickets_subtitle_dierenrijk':
    'Discover the animal within you and visit Dierenrijk. Children under 3 get to go wild for free. <br />Over the age of 3? Then you can benefit from our online discount.',
  'title.tickets_subtitle_speelland':
    'Are you ready for a great day of fun and play? Free admission for children under 3 years old. Over the age of 3? Then you can benefit from our online discount.',
  'title.tickets_subtitle_zooparc':
    'Are you ready to embark on an expedition? Children under the age of 3 are allowed to explore the park for free. <br />Over the age of 3? Then you can benefit from our online discount.',
  'title.timeframes': 'Arrival time',
  'title.timeframes_subtitle':
    "Below you'll find the start time for your adventure on your chosen day. Please note that the latest time you can arrive is half an hour before the park's closing time.",
  'title.total': 'Total',
  'title.unfinished_order':
    "Unfortunately, it is not possible to place multiple orders at the same time. Complete the other order first or click 'New order'. The other order will then be canceled.",
  'title.vouchers': 'Tickets with voucher codes',
  'title.vouchers_subtitle': 'If you have received any vouchercodes, enter them below',
  'title.your_order': 'Your order',
  'title.your_order_subtitle': 'We are almost there! But not quite… Check your order, finish your order and receive it directly in your inbox.',

  // Form validators
  'validator.email_invalid': 'Invalid email address',
  'validator.email_mismatch': 'The email addresses do not match',
  'validator.email_required': 'The email field is required',
  'validator.first_name_required': 'The first name field is required',
  'validator.last_name_required': 'The last name field is required',
  'validator.payment_method_required': 'You must select a payment method',
  'validator.privacy_required': 'You must agree to the privacy policy',
  'validator.products_required': 'You have not selected a product',
  'validator.required': 'This field is mandatory',
  'validator.terms_required': 'You must agree to the terms and conditions',
  'validator.phone_required': 'The phone number field is required',

  // Warnings
  'warning.no_products': 'No product selected',
  'warning.no_timeframes': 'We do not have any availability anymore on this day. Please have a look at another day.',
  'warning.old_browser': 'You are using an <strong>outdated</strong> browser. Please <a>upgrade your browser</a> to improve your experience and security.',
  'warning.default.no_timeframes': 'Helaas is er op deze dag geen beschikbaarheid meer. Kijk snel voor een andere dag.',

  // Warnings (LightSafari)
  'warning.4B4E039C-BD9D-EE11-A2EB-CDA6323A24CE.no_timeframes':
    'Helaas is er op deze dag geen Light Safari. Light Safari vindt plaats van 28 november 2024 t/m 9 maart van donderdag t/m zondag en in alle vakanties.',

  // Alerts
  'alert.closed.winter.season':
    '<b>PLEASE NOTE:</b> Klimrijk Brabant is open on a limited basis this winter. Please contact us at <a>info@libemafunfactory.nl</a> or at 088-9000360 for the possibilities.',

  // Somerdrome
  'tickets.timeframe.tabs_day': 'Day tickets',
  'tickets.timeframe.tabs_evening': 'Evening tickets',
  'tickets.timeframe_v2.tabs_day': '<strong>Day entrance</strong>',
  'tickets.timeframe_v2.tabs_evening': '<strong>Afternoon entrance</strong>',
  'tickets.timeframe.tabs_day.subtitle': 'Access from 10 a.m.',
  'tickets.timeframe.tabs_evening.subtitle': 'Access from 15 p.m.',
  'tickets.timeframe.tabs_day.price': '<strong>from € 18,00</strong>',
  'tickets.timeframe.tabs_evening.price': '<strong>€ 17,50</strong>',
  'tickets.timeframe.tabs_evening.price_beeksebergen': '<strong>€ 16,50</strong>',
  'somerdrome.day.timeframes_subtitle':
    'Choose an arrival time between <strong>10:00</strong> and <strong>15:00</strong> and have the option of staying at the Safaripark Beekse Bergen until as late as <strong>21:00</strong>.',
  'somerdrome.evening.timeframes_subtitle':
    'Choose an arrival time between <strong>15:00</strong> and <strong>20:00</strong> and have the option of staying at the Beekse Bergen safari park until as late as <strong>21:00</strong>.',

  // Order Confirmation (BeekseBergen)
  'order_confirmation.success.banner.title': 'Well prepared for adventure',
  'order_confirmation.success.banner.subtitle':
    'Consider downloading the Beekse Bergen app in advance. Here you will find information on everything Beekse Bergen has to offer and you’ll always have the map to hand.',
  'order_confirmation.success.banner.button': 'Discover the app',
  'order_confirmation.success.banner.button_url': 'https://www.beeksebergen.nl/en/app',

  // Order Confirmation (LightSafari)
  'order_confirmation.lightsafari.success.banner.title': 'Well prepared for adventure',
  'order_confirmation.lightsafari.success.banner.subtitle':
    'Consider downloading the Beekse Bergen app in advance. Here you will find information on everything you can do during the Light Safari, including which catering outlets are available, and you’ll always have the map to hand.',
  'order_confirmation.lightsafari.success.banner.button': 'Discover the app',
  'order_confirmation.lightsafari.success.banner.button_url': 'https://www.beeksebergen.nl/en/app',

  // Order Confirmation (BrewAtTheZoo)
  'order_confirmation.brewatthezoo.success.title': 'Gelukt! Je bent erbij!',
  'order_confirmation.brewatthezoo.success.subtitle': `<div>
  <p style="margin-bottom: 8px">
    Heb jij ook zo'n zin in Brew@theZoo bij Safaripark Beekse Bergen? Om goed voorbereid op 'biersafari' te gaan, hebben wij nog een paar
    belangrijke punten op een rijtje gezet:
  </p>
  <ol style="list-style:decimal;margin-bottom:8px;padding-left:24px">
    <li>
      Je ontvangt je gekochte Brew@theZoo tickets en reserveringsbevestiging op het opgegeven e-mailadres. Let op: mocht je jouw tickets
      niet direct ontvangen, controleer dan of deze in je spam of ongewenste e-mail terecht zijn gekomen.
    </li>
    <li>Neem je tickets én reserveringsbevestiging mee naar het evenement.</li>
    <li>Heb je tickets met korting gekocht? Zorg dan dat je je abonnement of Attractiepas ook meeneemt.</li>
    <li>Op de hoogte blijven van het laatste nieuws in Beekse Bergen? Schrijf je in voor de <a href="https://www.beeksebergen.nl/nieuwsbrief">nieuwsbrief</a>.</li>
  </ol>
  <p style="margin-bottom: 8px">
    We gaan ons best doen om weer een onvergetelijke editie van Brew@theZoo neer te zetten samen met jou. Tot dan, proost!
  </p>
</div>`,
  'order_confirmation.brewatthezoo.success.banner.title': 'Blijven slapen tijdens Brew@theZoo?',
  'order_confirmation.brewatthezoo.success.banner.subtitle':
    'Dat kan! Overnacht tussen de wilde dieren op het Safari Resort, midden in de natuur op het Lake Resort of met hotelservice in het nieuwe Safari Hotel.',
  'order_confirmation.brewatthezoo.success.banner.button': 'Bekijk alle accommodaties',
  'order_confirmation.brewatthezoo.success.banner.button_url': 'https://www.beeksebergen.nl/overnachten/zoek-en-boek',
}
